import { Link } from "react-router-dom";

const Footer = () => {
    return(
        <footer className="
        bg-light-green-50 
        absolute bottom-20 left-0 right-0 md:bottom-1 z-30
        h-auto w-auto mx-4 max-w-[80ch] sm:mx-16 lg:mx-auto
        rounded-t-sm
        ">
        {/* <footer className="
        bg-light-green-50 
        h-auto w-auto mx-4 md:max-w-4xl sm:mx-16 lg:mx-auto
        mb-24 sm:mb-0
        rounded-t-sm
        "> */}
            <ul className="
            flex flex-row flex-wrap gap-x-2
            justify-evenly items-center
            list-inside list-disc
            m-1
            text-sm text-primary-green leading-4
            ">
            {/* <ul className="
            flex flex-row flex-wrap gap-x-2
            justify-evenly items-center
            list-inside list-disc
            m-2 py-1
            text-sm text-primary-green leading-4
            "> */}
                <li>
                    We Love Birds ApS
                </li>
                <li>
                    CVR. 44971291
                </li>
                <li>
                    <Link to={"/betingelser"} className="inline-block">Handelsbetingelser</Link>
                </li>
                <li>
                    <Link to={"/betingelser"} className="inline-block">Fortrydelsesret</Link>
                </li>
            </ul>

        </footer>
    )
    
}

export default Footer;