import React, { useState, useEffect } from "react";
import { PortableText } from "@portabletext/react";
import zooCertificateImage from "../assets/images/zooCertifikat.png";

function HomePage() {
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/homepage`)
      .then((response) => response.json())
      .then((data) => setPageData(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  if (!pageData) return <div>Loading...</div>;

  return (
    <div className="container mx-auto px-4 relative min-h-[max(80vh,700px)]">
    {/* <div className="container mx-auto px-4 relative min-h-screen"> */}
      {/* Velkomstsektion - Øget bredde med md:max-w-4xl */}
      <section className="text-center mx-auto w-full md:max-w-4xl">
        <h2 className="text-4xl font-bold text-primary-green mb-8">
          {pageData.welcomeSection.heading}
        </h2>
        <div className="bg-light-green-50 p-6 rounded-lg">
          <p className="text-lg text-primary-green">
            {pageData.welcomeSection.subheading}
          </p>
        </div>
      </section>

      {/* Dynamiske sektioner */}
      <div className="flex flex-col items-center">
        {pageData.sections &&
          pageData.sections.map((section, index) => (
            <section key={index} className="my-16 max-w-2xl w-full text-center">
              <h3 className="text-2xl font-bold text-primary-green mb-6">
                {section.heading}
              </h3>
              <div className="prose prose-lg mx-auto">
                <PortableText value={section.content} />
              </div>
            </section>
          ))}
      </div>

      {/* Certificate Section */}
      <div className="
        absolute bottom-3 left-0 right-0
        flex flex-row flex-shrink
        place-content-evenly items-baseline gap-4
        mx-auto w-11/12 md:max-w-4xl
        ">
      {/* A stacked look with first certificate over the other two */}
      {/* <div className="
        grid grid-cols-2 md:flex sm:flex-row
        place-items-center gap-y-4 sm:place-content-evenly sm:items-baseline
        mx-auto w-full md:max-w-4xl
        relative bottom-2
        "> */}
        <a href="https://www.zoo.dk/stoet-verdens-dyr/doner" target="_blank" rel="noreferrer"
          className="block col-span-2 max-w-content"
        >
          <img
            src={zooCertificateImage}
            alt="Zoo Certificate"
            className="
            max-w-24 sm:max-w-36 h-auto
            rounded-lg
            shadow-black shadow-md
            "/>
        </a>

        <a href="https://www.ingenco2.dk/certificate/7694/da" className="block" target="_blank" rel="noreferrer">
          <img 
            src="https://assets.ingenco2.dk/icons/logo/regular_da.svg" 
            alt="" 
            className="
              w-36 h-auto md:w-36
              bg-light-green-50 
              border-2 border-green-600 rounded-lg
              "/>
        </a>
        {/* Placeholder for formating purposes */}
        {/* <a href="https://www.ingenco2.dk/certificate/7694/da" className="block" target="_blank" rel="noreferrer">
          <img 
            src="https://assets.ingenco2.dk/icons/logo/regular_da.svg" 
            alt="" 
            className="
              w-36 h-auto md:w-36
              bg-light-green-50 
              border-2 border-green-600 rounded-lg
              "/>
        </a> */}

        {/* Billedtekst til Zoo sponsorat */}
        {/* <p className="mb-2 text-sm md:text-base text-primary-green">
          We Love Birds støtter{" "}
          <a
            href="https://www.zoo.dk/stoet-verdens-dyr/doner"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold hover:underline"
          >
            Zoos arbejde
          </a>{" "}
          med at passe på verdens natur og bevare truede dyrearter.
        </p> */}
      </div>
      
    </div>
  );
}

export default HomePage;