// src/services/blogService.js
import sanityClient from './sanityClient';

export const fetchBlogPosts = async (limit = 10) => {
    const query = `*[_type == "blogPost"] | order(publishedAt desc)[0...${limit}]{
      _id,
      title,
      slug,
      description,
      body[0..6][_type == "block"],
      "imageUrl": featuredImage.asset->url,
      publishedAt
    }`;
    try {
      return await sanityClient.fetch(query);
    } catch (error) {
      console.error('Sanity query error:', error);
      throw new Error(`Failed to fetch blog posts: ${error.message}`);
    }
  };

export const fetchBlogPost = async (slug) => {
  const query = `*[_type == "blogPost" && slug.current == $slug][0]{
    title,
    "name": author->name,
    "categories": categories[]->title,
    "authorImage": author->image,
    body,
    "imageUrl": featuredImage.asset->url,
    publishedAt
  }`;
  try {
    return await sanityClient.fetch(query, { slug });
  } catch (error) {
    console.error('Sanity query error:', error);
    throw new Error(`Failed to fetch blog post: ${error.message}`);
  }
};

