import React, { useState, useEffect } from 'react';
import { PortableText } from '@portabletext/react';

const AboutUsPage = () => {
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/aboutus`)
      .then(response => response.json())
      .then(data => setAboutData(data))
      .catch(error => console.error('Error:', error));
  }, []);

  if (!aboutData) return <div>Indlæser...</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto">
        <div className="bg-cosmic-latte rounded-3xl shadow-lg overflow-hidden">
          <div className="bg-light-green-50 py-12">
            <h1 className="text-4xl font-bold text-primary-green text-center">{aboutData.title}</h1>
          </div>

          <div className="p-8">
            {/* Introduktion */}
            <section className="mb-12">
              <h2 className="text-3xl font-semibold text-primary-green mb-4">{aboutData.introSection?.heading}</h2>
              <div className="flex flex-col md:flex-row items-center">
                <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8">
                  <div className="prose">
                    <PortableText value={aboutData.introSection?.content} />
                  </div>
                </div>
                <div className="md:w-1/2">
                  <img
                    src={aboutData.introSection.imageUrl}
                    // src={`/api/image?imageId=${aboutData.introSection.image.asset._ref}`}
                    alt="Introduktion"
                    className="w-full h-auto rounded-lg shadow-md"
                  />
                </div>
              </div>
            </section>

            {/* Personlig Historie */}
            <section className="mb-12">
              <h2 className="text-3xl font-semibold text-primary-green mb-4">{aboutData.personalStory.heading}</h2>
              <div className="flex flex-col md:flex-row-reverse items-center">
                <div className="md:w-1/2 mb-6 md:mb-0 md:pl-8">
                  <div className="prose">
                    <PortableText value={aboutData.personalStory.content} />
                  </div>
                </div>
                <div className="md:w-1/2">
                  <img
                    src={aboutData.personalStory.imageUrl}
                    alt="Personlig Historie"
                    className="w-full h-auto rounded-lg shadow-md"
                  />
                </div>
              </div>
            </section>

            {/* Mission */}
            <section className="mb-12 bg-light-green-50 p-8 rounded-lg">
              <h2 className="text-3xl font-semibold text-primary-green mb-4">{aboutData.missionSection.heading}</h2>
              <p className="mb-4">{aboutData.missionSection.introduction}</p>
              {aboutData.missionSection.missionPoints && (
                <ol className="list-decimal list-inside space-y-2">
                  {aboutData.missionSection.missionPoints.map((point, index) => (
                    <li key={index} className="text-primary-green">
                      <span className="text-gray-800">{point}</span>
                    </li>
                  ))}
                </ol>
              )}
            </section>

            {/* Billedgalleri */}
            <section>
              <h2 className="text-3xl font-semibold text-primary-green mb-4">Galleri</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {aboutData.gallery.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image.galleryImages}
                      alt={image.caption || `Galleri billede ${index + 1}`}
                      className="w-full h-64 object-cover rounded-lg shadow-md"
                    />
                    {image.caption && (
                      <p className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 rounded-b-lg">
                        {image.caption}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;