import React, { useState, useMemo } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import {
  UserIcon,
  MapPinIcon,
  PhoneIcon,
  EnvelopeIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import intetBillede from "../../assets/images/intetbillede.png";

const UserPostCard = ({ post, onDelete, canDelete }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showContact, setShowContact] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const {
    title = "",
    featuredImageUrl,
    images = [],
    description = "",
    price = "",
    contactInfo = {},
    createdAt,
  } = post || {};

  const allImages = useMemo(() => {
    const imageArray = [featuredImageUrl, ...images].filter(Boolean);
    return imageArray.length > 0 ? imageArray : [intetBillede];
  }, [featuredImageUrl, images]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % allImages.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + allImages.length) % allImages.length
    );
  };

  const toggleContact = () => {
    setShowContact((prevState) => !prevState);
  };
  const toggleDetails = () => {
    setShowDetails((prevState) => !prevState);
  };

  const handleDelete = async () => {
    if (window.confirm("Er du sikker på, at du vil slette dette opslag?")) {
      onDelete?.();
    }
  };

  const randomRotation = useMemo(() => Math.random() * 2 - 1, []);

  if (!post) return null;

  const contactFields = [
    { icon: UserIcon, value: contactInfo.name, label: "Navn" },
    {
      icon: MapPinIcon,
      value:
        contactInfo.city && contactInfo.zipCode
          ? `${contactInfo.city}, ${contactInfo.zipCode}`
          : null,
      label: "Lokation",
    },
    { icon: PhoneIcon, value: contactInfo.phone, label: "Telefon" },
    { icon: EnvelopeIcon, value: contactInfo.email, label: "Email" },
  ].filter((field) => field.value);

  return (
    <div className="relative mx-auto max-w-4xl md:max-w-5xl lg:max-w-6xl">
      {/* magnet */}
      <div
        className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full z-10 flex items-center justify-center"
        style={{
          width: "3rem",
          height: "3rem",
          backgroundColor: "#2d836a",
          boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          className="bg-cosmic-latte rounded-full"
          style={{ width: "1.8rem", height: "1.8rem" }}
        ></div>
      </div>
      {/* container */}
      <div
        className="bg-cosmic-latte rounded-xl shadow-xl overflow-hidden flex flex-col relative"
        style={{
          // border: "0.5rem solid #E6F4EA",
          transform: `rotate(${randomRotation}deg)`,
          transition: "transform 0.3s ease-in-out",
          width: "calc(100% - 2rem)",
          margin: "0 auto",
        }}
      >
        <div className="flex flex-col w-full">
          <div className="mb-1 md:mb-0">
            {/* featured image */}
            <div className="relative w-full">
              <img
                src={allImages[currentImageIndex]}
                alt={title}
                className="relative object-cover rounded-xl shadow-md"
              />
              <button
                onClick={prevImage}
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-cosmic-latte rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors duration-200"
              >
                <ChevronLeftIcon className="h-5 w-5 text-gray-600" />
              </button>
              <button
                onClick={nextImage}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-cosmic-latte rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors duration-200"
              >
                <ChevronRightIcon className="h-5 w-5 text-gray-600" />
              </button>
            </div>
            {/* gallery */}
            <div className="flex mt-1 mx-2 overflow-x-auto">
              {allImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${title} - ${index + 1}`}
                  className={`w-14 h-14 object-cover rounded-lg cursor-pointer transition-all duration-200 ${
                    index === currentImageIndex
                      ? "border-3 border-primary-green"
                      : "border border-gray-200 hover:border-primary-green"
                  }`}
                  onClick={() => setCurrentImageIndex(index)}
                />
              ))}
            </div>
          </div>
          
          {/* heading, price, and text content */}
          <div className="mx-2 mb-2">
            <h2 className="text-xl text-balance font-bold text-primary-green md:m-1">{title}</h2>
            <p className="text-lg md:text-xl text-center font-semibold text-[#2d836a]">{price}</p>

            <div
              className={`
                prose prose-lg 
                ${showDetails ? "overflow-y-auto" : "overflow-y-hidden" }
                transition-all duration-300 ease-in
              `}
              style={{ 
                maxHeight: `${showDetails ? "350px" : "0px"}`,
                scrollbarWidth: "none"
              }}
            >
              <p className="text-gray-700 text-pretty whitespace-pre-wrap">{description}</p>
            </div>
          </div>
        </div>
        
        {/* Buttons */}
        <div className="grid grid-cols-3 justify-between items-center space-y-2 px-4 pb-6">
        {/* <div className="flex flex-col justify-between items-center space-y-2 px-6 pb-6"> */}
          <button
            onClick={toggleDetails}
            className="flex-1 bg-green-button-color text-cosmic-latte px-4 py-2 rounded-xl hover:bg-opacity-90 transition-colors duration-200 text-lg font-semibold col-span-2"
          >
            {showDetails ? "Skjul detaljer" : "Vis detaljer"}
          </button>
          <button
            onClick={toggleContact}
            className="flex-1 bg-green-button-color text-cosmic-latte px-4 py-2 rounded-xl hover:bg-opacity-90 transition-colors duration-200 text-lg font-semibold even:col-[2/span2] "
          >
            {showContact ? "Skjul kontakt" : "Vis kontakt"}
          </button>

          {canDelete && (
            <button
              onClick={handleDelete}
              className="bg-red-500 text-white px-4 py-2 rounded-xl hover:bg-red-600 transition-colors duration-200 col-span-2"
              title="Slet opslag"
            >
              <TrashIcon className="h-6 w-6" />
            </button>
          )}
        </div>

        <div
          className={`bg-light-green-50 border-t-4 border-primary-green transition-all duration-300 ease-in-out overflow-hidden ${
            showContact ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          <div className="p-6">
            <h3 className="text-xl font-semibold text-primary-green mb-4">
              Kontaktoplysninger
            </h3>
            <div className="space-y-2">
              {contactFields.map((field, index) => (
                <div key={index} className="flex items-center">
                  <field.icon className="h-5 w-5 text-primary-green mr-3" />
                  <p className="text-gray-800">{field.value}</p>
                </div>
              ))}
            </div>
            {createdAt && (
              <div className="flex items-center text-sm text-gray-600 mt-4">
                <CalendarIcon className="h-4 w-4 mr-2" />
                Oprettet: {new Date(createdAt).toLocaleDateString()}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPostCard;
