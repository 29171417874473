import React from 'react';
import { Link } from 'react-router-dom';
import { PortableText } from '@portabletext/react';

const BlogPostMagnet = ({ post }) => {
  const randomRotation = Math.random() * 6 - 3; // Random rotation between -3 and 3 degrees

  const portableTextPreview = {
    block: {
      normal: ({ children }) => (
        <p >{children}</p>
      )
    }
  }

  return (
    <Link to={`/blog/${post.slug.current}`} className="block transform transition-all duration-300 hover:scale-105">
      <div className="relative pt-4 mx-auto">
        {/* Magnet */}
        <div 
          className="absolute top-0 left-1/2 transform -translate-x-1/2 w-9 h-9 rounded-full z-20"
          style={{
            backgroundColor: '#2d836a',
            boxShadow: '0 3px 5px rgba(0,0,0,0.1)'
          }}
        ></div>

        <div 
          className="
            grid grid-cols-2
            gap-4 items-center
            bg-cosmic-latte p-4 rounded-xl shadow-lg
            z-10
          "
          style={{ 
            transform: `rotate(${randomRotation}deg)`,
            transformOrigin: 'center',
            width: '100%',
            // height: '450px',
            border: "0.5rem solid rgb(230, 244, 234)"
          }}
        >
          {/* Image */}
          <img 
            src={post.imageUrl} 
            alt={post.title}
            className="object-cover rounded-xl shadow-md"
          />

          {/* Title */}
          <h2 className="text-primary-green text-2xl font-semibold text-center">{post.title}</h2>
          {/* Preview */}
          <p className='col-span-2 text-center text-lg'>{post.description}</p>
          <div className='col-span-2 line-clamp-3 max-h-[3lh]'>
            <PortableText value={post.body} components={portableTextPreview} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogPostMagnet;